import React from 'react'

export const Contact = () => {
    return (
        <section className='contact-container'>
            {/* {navState === 3 && <Cover isNavChanging={isNavChanging} />} */}
            Contact
        </section>
    )
}

export default Contact;